<template>
<div class="row">
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 chat-left scroll-bar border-right-light pl-4 pr-4" style="min-height: 100vh">
        <div action="#" class="pl-3 pt-3 d-flex flex-row">
            <h1 class="my-auto"><router-link to="/" style="cursor: pointer" class="border-right mt-3"><i class="feather-home text-warning mr-3"></i></router-link></h1>
            <div class="search-form mx-3" style="width: 100%">
                <i class="ti-search font-xs"></i>
                <input type="search" @keyup="searchUsers" v-model="search" id="users" list="users" class="form-control text-grey-500 mb-0 bg-greylight border-0" placeholder="Search here.">
            </div>
        </div>
        <hr>
        <div class="h-auto bg-white position-absolute" style="z-index:99; width: 97%;" v-show="panelSearch">
            <li class="bg-transparent list-group-item no-icon pb-3" v-for="(item, j) in users" :key="j" @click="createOrFindRoom(item.username)" style="cursor:pointer">
                <div>
                    <figure class="avatar float-left mb-0 mr-3">
                        <img :src="item.photo ?  item.photo : '/images/profile.png'" alt="image" class="rounded-circle w45">
                    </figure>
                    <h3 class="fw-700 mb-0 mt-1">
                        <p class="font-xsss text-grey-900 text-dark d-block" >{{item.name}}<br><small>@{{item.username}}</small></p>
                    </h3>
                </div>
            </li>
        </div>
        <div class="section full mt-2 mb-2 px-3" v-if="!loadData" v-show="!panelSearch">
            <ul class="list-group list-group-flush" v-if="dataExist">
                <li class="bg-transparent list-group-item no-icon px-0 py-3 border-bottom" v-for="(room, i) in room" :key="i">
                    <router-link :to="'/message/'+room.opponent.username">
                        <figure class="avatar float-left mb-0 mr-3">
                            <img :src="room.opponent.photo ?  room.opponent.photo : '/images/profile.png'" alt="image" class="rounded-circle w50">
                        </figure>
                        <h3 class="fw-700 mb-0 mt-0">
                            <router-link class="font-xsss text-grey-900 text-dark d-block" :to="'/message/'+room.opponent.username">{{room.opponent.name}}</router-link>
                        </h3>
                        <span class="d-block text-truncate fw-600">{{room.last_message.message}}</span>
                        <span class="float-right fw-500 font-xsssss mt-2">{{room.last_message.created_at | datetime}}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="row" v-if="loadData">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" v-show="dataNotExist">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <img :src="'/images/empty.png'" alt="" width="300">
                <h1 class="text-muted">Your have no message.</h1>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: "Message",
        data() {
            return {
                media : process.env.VUE_APP_URL_CLOUD,
                user: {},
                users: {},
                room: {},
                search: '',
                panelSearch: this.search,
                loadData: true,
                dataExist: false,
                dataNotExist: false,
            }
        },
        created(){
            this.getUser()
        },
        mounted(){
            this.getRoom()
        },
        filters:{
            datetime: function (date) {
                return moment(date).calendar();
            }
        },
        methods: {
            async getUser() {
                this.user = JSON.parse(localStorage.getItem('user'))
            },
            async getRoom() {
                await axios.get(`${process.env.VUE_APP_URL_API}/chat/room`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.loadData = false
                    if (res.data.length === 0) {
                        this.dataNotExist = true
                    } else {
                        this.dataExist = true
                        this.room = res.data
                    }
                }).catch((err) => console.error(err))
            },
            async createOrFindRoom(username){
                var data = {
                    opponent_username: username
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/chat/room`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(() => {
                    this.$router.push({ path: 'message/'+username })
                })
            },
            async searchUsers() {
                if (this.search !== '') {
                    this.panelSearch = true;
                    await axios.get(`${process.env.VUE_APP_URL_API}/chat/user?search=${this.search}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.users = res.data
                    }).catch((err) => console.error(err))
                } else {
                    this.panelSearch = false;
                }
            },
        }       
    }
</script>
